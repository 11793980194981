<header [ngClass]="{'scrolling':!userAtTop}">
  <div class="container">
    <div class="logo"><img src="/assets/logo.png"></div>
  </div>

  <div class="rsvplink">
    <a href="/rsvp">RSVP</a>
  </div>
</header>

<section id="hero">
  <div class="container">
    <div class="hero-info">
      <h1>9.30.22</h1>
      <p class="names">Jennifer Johnson and Brian Stephens</p>
      <p class="city">Benham's Grove, Centerville OH</p>
    </div>
  </div>
</section>

<section id="about">
  <div class="story">
    <div class="container">
      <div class="image"><img src="/assets/proposal_2.jpg"></div>
      <div class="text">
        <p class="smalltext"><i>Proposal</i></p>
        <h2>October 13, 2021</h2>
        <p>Disney World's Hollywood Studios Park in Florida. Brian surprised JJ with the question during a photoshoot in front of the Hollywood Tower Hotel.</p>
      </div>
    </div>
  </div>
  <div class="art-frames">
    <div class="frame"><img src="/assets/art-3.png"></div>
    <div class="frame"><img src="/assets/art-1.png"></div>
    <div class="frame"><img src="/assets/art-2.png"></div>
  </div>
</section>

<section id="wedding">
  <div class="container">
    <h1>Wedding Day</h1>
    <div class="location-info">
      <div class="text-side">
        <div class="info">
          <h3>Date</h3>
          <p>Sepetember 30th, 2022<br><a *ngIf="isDesktop" download class="userAction dl" href="/assets/ics/jjandbrian-wedding.ics" target="_blank">Add to Calendar</a><a *ngIf="!isDesktop" class="userAction dl" href="/assets/ics/jjandbrian-wedding.ics">Add to Calendar</a></p>
        </div>
        <div class="info">
          <h3>Time</h3>
          <p>6:30 PM</p>
        </div>
        <div class="info">
          <h3>Where</h3>
          <p>Benham's Grove<br>166 N Main Street<br>Centerville, OH 45459<br><a class="userAction ol" href="https://goo.gl/maps/p6khoPfQaDf19bLW8" target="_blank">Get Directions</a></p>
        </div>
      </div>
      <div class="map-side">
        <div #mapContainer id="map"></div>
      </div>
    </div>
  </div>
</section>

<section id="additionalInfo">
  <div class="container">
    <h1>Additional Information</h1>
    <div class="info">
      <h2>RSVP</h2>
      <p>Head to the <a href="/rsvp">RSVP page</a> and fill out the form.</p>
    </div>
    <div class="info">
      <h2>Registry</h2>
      <p>View the <a href="/registry">registry page</a> for more details</p>
    </div>
    <div class="info">
      <h2>Weather Expected</h2>
      <p>Weather on an evening in late September is expected to be fairly warm still, with highs typically in the 70s.</p>
    </div>
    <div class="info">
      <h2>Indoor Options</h2>
      <p>Both our ceremony and reception will be held outdoors but the Gerber House will be open with AC throughout the evening.</p>
    </div>
    <div class="info">
      <h2>Dress Code</h2>
      <p>California Casual&mdash;with the beginning of Autumn just starting, the air should still be fairly warm. We'd love to see you in something you’re comfortable in button-up shirts, long flowy dresses, or whatever brings you joy.</p>
    </div>
    <div class="info">
      <h2>Following Ceremony</h2>
      <p>Enjoy happy hour snacks and beverages at the Gerber House Patio as the newly weds soak in their first hour betrothed. Dinner under the canopy to begin after happy hour, with dancing to follow.</p>
    </div>
    <div class="info">
      <h2>Parking</h2>
      <p>Benhams Grove includes complimentary parking on site, please refer to map for best options.</p>
    </div>
    <div class="info">
      <h2>Additional Guests</h2>
      <p>We are at capacity with our venue which means we are sadly unable to accommodate additional guests.</p>
    </div>
    <div class="info">
      <h2>Hotels</h2>
      <p>We recommend staying at <a href="https://www.google.com/url?q=https://www.hilton.com/en/hotels/daydsgi-hilton-garden-inn-dayton-south-austin-landing/" target="_blank">the Hilton</a> or <a href="https://austinlanding.com/location/staybridge-suites" target="_blank">Staybridge Suites</a> at Austin Landing, this is a convenient area with dining and easy access to 75. It is about a 10 minute drive to Benhams Grove.</p>
    </div>
  </div>
</section>



<footer>
  <div class="container">
    <a href="mailto:jjandbrian22@gmail.com"><span class="email-icon"></span></a>
  </div>
</footer>





