<div class="logo"><img src="/assets/logo.png"></div>

<section id="rsvp">
	<p><a href="/" class="goback">Home</a></p>
	<div class="container">
		<h1>RSVP</h1>
		<div class="form" *ngIf="!formSuccess">
			<form (submit)="handleFormSubmit()">
				<div class="form-field">
					<label>Party <span>*</span></label>
					<div class="select-wrap">
						<select name="form-party" [ngModel]="formParty" (ngModelChange)="handlePartyChange($event)">
							<option value="-1">Select one...</option>
							<ng-container *ngFor="let guest of guestList; let i = index;">
								<option value="{{i}}">{{guest.name}}</option>
							</ng-container>
						</select>
					</div>
				</div>
				<div class="form-field">
					<label>Total Attending <span>*</span></label>
					<div class="select-wrap">
						<select name="form-number" [disabled]="guestListSelectedIndex < 0" [ngModel]="formNumber" (ngModelChange)="handleNumberChange($event)">
							<option value="-1">Select one...</option>
							<option value="0">We are unable to attend</option>
							<ng-container *ngIf="guestListSelectedIndex > -1">
								<ng-container *ngFor="let in of counter(guestList[guestListSelectedIndex].number); let i = index">
									<option value="{{i+1}}">{{i+1}}</option>
								</ng-container>
							</ng-container>
						</select>
					</div>
				</div>
				<div class="form-field">
					<label>Message</label>
					<textarea name="form-message" [(ngModel)]="formMessage"></textarea>
				</div>
				<div class="form-field submit">
					<button [disabled]="guestListSelectedIndex < 0 || formNumber < 0">Submit</button>
				</div>
			</form>
		</div>
		<div class="formSuccess" *ngIf="formSuccess">

			<div class="card" [ngClass]="{'show': formNumber > 0}">
				<h2>Hooray!</h2>
				<p>The form submitted successfully, we're excited to see you there!</p>
				<div class="image">				
					<img class="yay" src="/assets/yay.gif">
					<img class="frame" src="/assets/frame.png">
				</div>
			</div>

			<div class="card" [ngClass]="{'show': formNumber == 0}">
				<h2>We'll miss you, but we understand!</h2>
				<p>The form submitted successfully, thank you for letting us know!</p>
				<div class="image">				
					<img class="aww" src="/assets/aww.gif">
					<img class="frame" src="/assets/frame.png">
				</div>
			</div>

		</div>
	</div>
</section>