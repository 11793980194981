import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rsvp',
  templateUrl: './rsvp.component.html',
  styleUrls: ['./rsvp.component.scss']
})

export class RsvpComponent implements OnInit {

  guestList: { name: string; number: number }[] = [];
  guestListSelectedIndex: number = -1;
  formParty: any = "";
  formMessage: any = "";
  formNumber: number = -1;
  formSuccess: boolean = false;

  constructor( private http: HttpClient) { }

  ngOnInit(){
    this.initGuestList();
  }

  initGuestList(){
    this.guestList = [
      { name: "Alicia Grodecki & Aaron Hardy", number: 2 },
      { name: "Amber Hoover & guest", number: 2 },
      { name: "Angela Stastny & Mike Smith", number: 2 },
      { name: "Caitlin, Phil, Alexa, Lilly, & Philip Smith", number: 5 },
      { name: "Deanna, Tony, & Luna Pesarek", number: 3 },
      { name: "Diera, Alex, Weylon, & Ophelia", number: 4 },
      { name: "Ellen & Dave Stephens", number: 2 },
      { name: "Jamie, Micheal, and Poppy Stephens", number: 3 },
      { name: "Jenna & Josh Rigg", number: 2 },
      { name: "Josh Stephens & guest", number: 2 },
      { name: "Joyce Stephens & guest", number: 2 },
      { name: "Kim & Nick Johnson", number: 2 },
      { name: "Laura & Steve Shambeck", number: 2 },
      { name: "Luke Barrett & guest", number: 2 },
      { name: "Luke, Emily, Liam, and Kylee Hoover", number: 4 },
      { name: "Paige & Nathan Bolling", number: 2 },
      { name: "Pastor Terry", number: 1 },
      { name: "Sydney Johnson & Trevor Hayslip", number: 2 },
      { name: "Sylvia Vo & guest", number: 2 },
      { name: "Trisha Franklin & guest", number: 2 },
    ]
  }

  handlePartyChange(evt){
    this.guestListSelectedIndex = evt;
    this.formNumber = -1;
  }

  handleNumberChange(evt){
    this.formNumber = evt;
  }

  counter(i: number) {
    return new Array(i);
  }

  handleFormSubmit(){
    event.preventDefault();

    let data = {
      "Party": this.guestList[this.guestListSelectedIndex].name,
      "Attending": this.formNumber,
      "Message": this.formMessage  
    }

    this.http.post("https://formspree.io/f/xayvgvzz", data).subscribe(res => {
      if(res["ok"]){
        this.formSuccess = true;
      } else {
        alert("There was an error submitting your RSVP, please try again.");  
      }
    },
    error => {
      alert("There was an error submitting your RSVP, please try again.");
    }, () => {
      // ...
    })
  }



}
