import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { WeddingComponent } from './wedding/wedding.component';
import { RegistryComponent } from './registry/registry.component';
import { FourOhFourComponent } from './four-oh-four/four-oh-four.component';
import { RsvpComponent } from './rsvp/rsvp.component';

const routes: Routes = [
  { path: '', component: WeddingComponent },
  { path: 'rsvp', component: RsvpComponent },
  { path: 'registry', component: RegistryComponent },
  { path: 'gifts', component: RegistryComponent },
  { path: 'giveusstuff', component: RegistryComponent },
  { path: '**', component: FourOhFourComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
