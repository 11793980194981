<div class="logo"><img src="/assets/logo.png"></div>

<section id="registry">
	<p><a href="/" class="goback">Home</a></p>
	<div class="container">
		<p class="names">JJ and Brian</p>
		<h1>Registry</h1>
		<p>We're heading to Europe for our honeymoon: Edinburgh, Scotland > Paris, France > Santorini, Greece. This will be our first time traveling abroad, so we are excited! We're accepting cash gifts via <a target="_blank" href="https://www.paypal.com/paypalme/brianstephens">paypal</a>, which will <strong>contribute to our honeymoon/home fund</strong>. We are also <a href="https://www.crateandbarrel.com/gift-registry/brian-stephens-and-jennifer-johnson/r6493090" target="_blank">registered at Crate and Barrel</a> for various items to fill our home. Thank you!</p>
		<div class="options">
			<div class="option"><a href="https://www.paypal.com/paypalme/brianstephens" target="_blank" class="paypal"><img src="/assets/pp_logo.png"></a></div>
			<div class="option"><a href="https://www.crateandbarrel.com/gift-registry/brian-stephens-and-jennifer-johnson/r6493090" target="_blank" class="crateAndBarrel"><img src="/assets/cab_logo.png"></a></div>
		</div>
	</div>
</section>